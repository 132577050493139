<template>
  <vs-popup class="sm:popup-w-80" title="A/R Aging Detail " :active="isActive" v-on:update:active="emitModalIsActive">
    <div :class="{'pointer-events-none opacity-50': loading.getData}">
      <div class="h-screen-80">
        <div class="flex h-full flex-col">
          <div class="flex-none">
            <div class="vx-row mb-3">
              <div class="vx-col sm:w-12/12 w-full">
                <div class="vx-row">
                  <div class="vx-col sm:w-11/12 w-full">
                    <div class="flex flex-wrap items-center space-x-4">
                      <div class="flex items-center justify-start space-x-2">
                        <label class="text-xs">Person:</label>
                        <vs-input class="w-48" :value="filter.nama_person" readonly/>
                      </div>
                      <div class="flex items-center justify-start space-x-2">
                        <label class="text-xs">Proyek:</label>
                        <vs-input class="w-48" :value="filter.nama_proyek" readonly/>
                      </div>
                      <div class="flex items-center justify-start space-x-2">
                        <label class="text-xs">AR S/d Tgl:</label>
                        <vs-input class="w-48" :value="filter.periode_akhir" readonly/>
                      </div>
                    </div>
                  </div>
                  <div class="vx-col sm:w-1/12 w-full">
                    <div class="flex items-center justify-items-center justify-end space-x-2 h-full">
                      <QueryEditor v-if="$can('view_query')" code="AR_AGING_DETAIL"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row mb-3">
              <div class="vx-col sm:w-12/12 w-full">
                <p class="text-xs text-primary">Periode aging dihitung berdasarkan tanggal hari ini, {{ currentDate }}.</p>
              </div>
            </div>
          </div>
          <div class="flex-1 d-theme-dark-bg overflow-auto border border-solid d-theme-border-grey-light">
            <table class="w-full table-fixed table-sticky border-collapse">
              <colgroup>
                <col class="w-20"/>
                <col class="w-32"/>
                <col class="w-32"/>
                <col class="w-32"/>
                <col class="w-64"/>
                <col class="w-32"/>
                <col class="w-32"/>
                <col class="w-32"/>
              </colgroup>
              <!-----------TABLE HEAD------------>
              <thead class="font-extrabold border border-solid d-theme-border-grey-light">
                <tr class="d-theme-dark-bg">
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Trx Date</th>
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Created At</th>
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Tipe</th>
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">No. Transaksi</th>
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Keterangan</th>
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Debet</th>
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Kredit</th>
                  <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-xs text-center">Saldo</th>
                </tr>
              </thead>
              <!-----------TABLE BODY------------>
              <tbody v-for="group in groups" :key="group.uuid">
                <!--group-->
                <tr class="cursor-pointer hover-bg-primary-transparent-25" @click="group.expanded = !group.expanded">
                  <td class="border border-solid d-theme-border-grey-light p-2 font-medium" :colspan="group.expanded ? 8 : 5">
                    <div class="flex items-center space-x-2">
                      <feather-icon :icon="group.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" svg-classes="h-5 w-5" class="mr-2"/>
                      <span class="font-bold text-sm">Period: {{ group.name }}</span>
                      <span class="text-xs opacity-75">({{ group.alias }})</span>
                    </div>
                  </td>
                  <template v-if="!group.expanded">
                    <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ group.totalDebet | idr }}</th>
                    <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ group.totalKredit | idr }}</th>
                    <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ group.totalSaldo | idr }}</th>
                  </template>
                </tr>
                <template v-if="group.expanded">
                  <tr v-for="(item) in group.items" :key="item.uuid">
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.tanggal }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.created_at }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.tipe_transaksi }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.no_transaksi }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.keterangan }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.debet | idr }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.kredit | idr }}</td>
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.saldo | idr }}</td>
                  </tr>
                  <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                    <th colspan="5" class="border border-solid d-theme-border-grey-light p-2 text-center text-xs">Subtotal Period: {{ group.name }}</th>
                    <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ group.totalDebet | idr }}</th>
                    <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ group.totalKredit | idr }}</th>
                    <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ group.totalSaldo | idr }}</th>
                  </tr>
                </template>
              </tbody>
              <!-----------TABLE FOOT------------>
              <tfoot>
                <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light text-primary">
                  <th colspan="5" class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-sm text-center font-bold">GRAND TOTAL</th>
                  <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-right font-bold">{{ footer.grandTotalDebet | idr }}</th>
                  <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-right font-bold">{{ footer.grandTotalKredit | idr }}</th>
                  <th class="border border-solid d-theme-border-grey-light p-2 text-sm text-right font-bold">{{ footer.grandTotalSaldo | idr }}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import ArAgingRepository from '@/repositories/accounting/ar-aging-repository'
import QueryEditor from '@/views/components/query-editor/QueryEditor'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import moment from 'moment/moment'

export default {
  name: 'ArAgingDetail',
  props: {
    isActive: { required: true, type: Boolean },
    params: { required: false, type: Object }
  },
  components: {
    QueryEditor
  },
  watch: {
    isActive (active) {
      if (active) {
        this.filter = {
          tipe_person: this.params.tipe_person,
          id_person: this.params.id_person,
          nama_person: this.params.nama_person,
          id_proyek: this.params.id_proyek,
          nama_proyek: this.params.nama_proyek,
          periode_akhir: this.params.periode_akhir
        }
        this.getData()
      }
    }
  },
  computed: {
    footer () {
      return {
        grandTotalDebet: _.sumBy(this.groups, 'totalDebet'),
        grandTotalKredit: _.sumBy(this.groups, 'totalKredit'),
        grandTotalSaldo: _.sumBy(this.groups, 'totalSaldo')
      }
    },
    currentDate () {
      return moment().format('DD MMMM YYYY')
    }
  },
  data () {
    return {
      errors: null,
      loading: {
        getData: false
      },
      filter: {},
      groups: []
    }
  },
  methods: {
    getData () {
      this.loading.getData = true

      ArAgingRepository.getArAgingListDetail(this.filter)
        .then(response => {
          this.groups = this.buildGroups(response.data.data)
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.getData = false
        })
    },

    buildGroups (data) {
      const generateItems = (groupName) => {
        let saldo = 0
        return _.map(_.filter(data, item => item.period === groupName), item => {
          saldo += (item.debet - item.kredit)
          item.saldo = saldo
          return item
        })
      }

      const generateGroups = () => {
        return _.map(_.uniqBy(data, item => item.period), item => {
          const items = generateItems(item.period)
          return {
            uuid: uuid(),
            name: item.period,
            alias: item.period_alias,
            expanded: true,
            totalDebet: _.sumBy(items, item => item.debet),
            totalKredit: _.sumBy(items, item => item.kredit),
            totalSaldo: items[items.length - 1].saldo,
            items: items
          }
        })
      }

      return generateGroups()
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>

<style scoped>
/*table sticky*/
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
