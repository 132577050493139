<template>
  <div>
    <vx-tooltip :text="'Edit Query: ' + code" delay="1.0s">
      <div @click="isActive = true" class="rounded bg-danger py-1 px-1 flex items-center cursor-pointer justify-center justify-items-center">
        <feather-icon icon="EditIcon" svgClasses="h-3 w-3" class="mr-1 text-white"/>
        <span class="text-white text-xs select-none font-medium">Query</span>
      </div>
    </vx-tooltip>

    <vs-popup class="sm:popup-w-70" title="Query Editor" :z-index="100002" :active="isActive" v-on:update:active="toggleActive">
      <div v-if="isLoadingGetInitData" class="animate-pulse">
        <p>Loading...</p>
        <vs-progress indeterminate color="primary" :height="1"/>
      </div>
      <div v-else class="h-screen-80 flex flex-col">
        <div class="flex-none">
          <div class="w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="flex-none">
          <div class="w-full">
            <vs-alert class="w-full h-auto" :title="'QUERY CODE: ' + initData.code" color="primary" active="true">{{ initData.description }}</vs-alert>
            <p class="mb-2"></p>
            <label class="ml-1 mb-3 font-medium text-xs">Query:</label>
          </div>
        </div>
        <div class="flex-grow mb-5">
          <div class="w-full h-full d-theme-border-grey-light border border-solid">
            <div class="h-full">
              <AceEditor v-if="isActive" ref="aceEditor" v-model="data.query" @init="onInitAceEditor" lang="mysql" theme="dracula" :options="aceEditorOptions" width="100%"></AceEditor>
            </div>
          </div>
        </div>
        <div class="flex-none">
          <div class="h-full w-full flex justify-between items-center">
            <div class="flex flex-wrap items-center justify-start pl-1">
              <div v-if="isAnyUnsavedChanges">
                <p class="text-sm text-primary font-normal">Perubahan Terdeteksi <span class="mx-1">|</span> <span class="underline cursor-pointer font-semibold" @click="resetQuery">Reset Query</span></p>
              </div>
            </div>
            <div class="flex items-center">
              <p class="mr-4 text-primary cursor-pointer select-none" @click="beautify">Beautify</p>
              <vs-button class="mr-3" type="border" @click="toggleActive(false)">Batal</vs-button>
              <vs-button :disabled="isLoadingSave || !isAnyUnsavedChanges" @click="save">
                <span v-if="isLoadingSave" class="animate-pulse">Menyimpan...</span>
                <span v-if="!isLoadingSave">Simpan</span>
              </vs-button>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import QueryEditorRepository from '@/repositories/system/query-editor-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import { format } from 'sql-formatter-plus'
import _ from 'lodash'

export default {
  name: 'QueryEditor',
  props: ['code'],
  components: {
    ValidationErrors,
    AceEditor: require('vue2-ace-editor')
  },
  watch: {
    isActive (active) {
      if (active && this.isFirstActive) {
        this.getInitData()
        this.isFirstActive = false
      }
      if (!active) {
        this.resetQuery()
      }
    },
    code (newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.isFirstActive = true
      }
    }
  },
  computed: {
    isAnyUnsavedChanges () {
      return !_.isEqual(_.cloneDeep(this.data.query), _.cloneDeep(this.initData.query))
    }
  },
  data () {
    return {
      isFirstActive: true,
      isActive: false,
      isLoadingSave: false,
      isLoadingGetInitData: false,
      errors: null,
      initData: {
        query: ''
      },
      data: {
        query: ''
      },
      aceEditorOptions: {
        showPrintMargin: false,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        highlightActiveLine: true,
        highlightSelectedWord: true
      }
    }
  },
  methods: {
    getInitData () {
      this.isLoadingGetInitData = true

      QueryEditorRepository.show(this.code)
        .then(response => {
          this.initData = response.data.data
          this.data.query = this.initData.query
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoadingGetInitData = false
        })
    },

    save () {
      this.errors = null
      this.isLoadingSave = true

      const params = {
        code: this.initData.code,
        queryText: this.data.query
      }
      QueryEditorRepository.save(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingSave = false
        })
    },

    onInitAceEditor (editor) {
      require('brace/ext/language_tools')
      require('brace/ext/searchbox')
      require('brace/mode/mysql')
      require('brace/theme/dracula')
      require('brace/snippets/mysql')
      editor.focus()
    },

    toggleActive (active) {
      if (!active && this.isAnyUnsavedChanges) {
        return this.confirmClose()
      }
      this.isActive = active
    },

    confirmClose () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Terdapat perubahan yang belum disimpan, yakin ingin close?',
        acceptText: 'Ya',
        cancelText: 'Batal',
        accept: () => {
          this.isActive = false
          this.resetQuery()
        }
      })
    },

    resetQuery () {
      this.data.query = this.initData.query
    },

    beautify () {
      this.data.query = format(this.data.query, {
        language: 'sql',
        uppercase: true
      })
    },

    onSuccess () {
      this.isActive = false
      this.isFirstActive = true
      this.notifySuccess('Query berhasil disimpan.')
    }
  }
}
</script>
