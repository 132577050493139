import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/system/queryEditor'

export default {
  show (queryCode) {
    return httpClient.get(`${endpoint}/${queryCode}`)
  },

  save (params) {
    return httpClient.post(`${endpoint}/save`, params)
  }
}
